import React, { forwardRef } from 'react'
import { TooltipBox as Box, TooltipBoxProps as BoxProps } from '../Box/TooltipBox'

export type StackProps = {
    horizontal?: boolean
    direction?: BoxProps['direction']
    gap?: BoxProps['gap']
} & BoxProps

export const Stack = forwardRef<HTMLElement, StackProps>(({ horizontal, ...boxProps }, ref) => (
    <Box
        ref={ref}
        direction={boxProps.direction ?? (horizontal ? 'row' : 'column')}
        {...boxProps}
    />
))

Stack.displayName = 'Stack'
