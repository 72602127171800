import { useCallback, useContext, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Address } from 'use-towns-client'
import { PanelContext } from '@components/Panel/PanelContext'
import { CHANNEL_INFO_PARAMS_VALUES } from 'routes'
import { useDevice } from 'hooks/useDevice'
import { Analytics } from 'hooks/useAnalytics'

export const usePanelActions = () => {
    const { isTouch } = useDevice()
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const context = useContext(PanelContext)
    const contextStackId = context?.stackId
    const contextParentRoute = context?.parentRoute
    const isRootPanel = context?.isRootPanel

    return {
        data: useMemo(() => searchParams.get('data'), [searchParams]),
        isStacked: useMemo(() => {
            if (isTouch) {
                return !isRootPanel
            } else {
                return searchParams.get('stacked')
            }
        }, [isRootPanel, isTouch, searchParams]),
        openPanel: useCallback(
            (
                panel: CHANNEL_INFO_PARAMS_VALUES,
                options: {
                    stackId?: string
                    channelId?: string
                    channelFormId?: string // unique identifier for create channel form
                    profileId?: string
                    roleId?: string
                    data?: string
                    roles?: 'new'
                    assetSource?: Address
                    eventId?: string
                    streamId?: string
                } = {},
            ) => {
                const { stackId = contextStackId, ...restOptions } = options

                setSearchParams({
                    panel,
                    stackId,
                    // for desktop, opening a panel from within a panel should
                    // "stack it" allowing the user to go back to the previous panel
                    ...(context.isPanelContext ? { stacked: 'true' } : {}),
                    ...restOptions,
                })
            },
            [context, contextStackId, setSearchParams],
        ),
        closePanel: useCallback(
            (params?: { preventPopStack?: boolean; force?: boolean }) => {
                const panel = searchParams.get('panel')
                if (panel) {
                    Analytics.getInstance().track(
                        'closed panel',
                        {
                            panel,
                        },
                        () => {
                            console.log('[analytics] closed panel', panel)
                        },
                    )
                }
                if (params?.force) {
                    searchParams.delete('panel')
                    setSearchParams({ ...searchParams })
                    return
                }
                if (contextParentRoute) {
                    navigate(contextParentRoute)
                } else if ((searchParams.get('stacked') && !params?.preventPopStack) || isTouch) {
                    navigate(-1)
                } else if (searchParams.get('panel')) {
                    searchParams.delete('panel')
                    setSearchParams({ ...searchParams })
                }
            },
            [contextParentRoute, isTouch, navigate, searchParams, setSearchParams],
        ),

        isPanelOpen: useCallback(
            (panel: CHANNEL_INFO_PARAMS_VALUES) => {
                return searchParams.get('panel') === panel
            },
            [searchParams],
        ),
        currentlyOpenPanel: useMemo(() => searchParams.get('panel'), [searchParams]),
    }
}
