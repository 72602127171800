export const EVERYONE = 'everyone'
export const GATED = 'gated'
export const MEMBERSHIP_TYPE = 'gatingType'
export const TOKENS = 'tokens'
export const SPACE_NAME = 'spaceName'

// TODO: clean up this file when further along with v2 space form
export const ROLE_MINTER = 'minter'
export const ROLE_MEMBER = 'member'
export const TEMPORARY_SPACE_ICON_URL = 'temporarySpaceIconUrl'

// See error types: https://github.com/HereNotThere/harmony/blob/main/contracts/src/libraries/Errors.sol
export const ERROR_INVALID_PARAMETERS = 'InvalidParameters'
export const ERROR_NAME_LENGTH_INVALID = 'NameLengthInvalid'
export const ERROR_NAME_CONTAINS_INVALID_CHARACTERS = 'NameContainsInvalidCharacters'
export const ERROR_SPACE_ALREADY_REGISTERED = 'SpaceAlreadyRegistered'

export const ENTITLEMENT_NOT_ALLOWED = 'Entitlement__NotAllowed'
export const ERROR_GATE_FACET_SERVICE_NOT_ALLOWED = 'GateFacetService__NotAllowed'
